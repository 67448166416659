<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { type IProject, useProjectsStore } from "~/stores/db/projects";
import { useUIStore } from "~/stores/ui";
import { useDisplay, useTheme } from "vuetify";
// import { useLeftFormMenu } from "~/composables/useLeftFormMenu";
// import { type STORAGE_CURRENT_PROJECT } from "~/types";

const { mobile } = useDisplay();
const { data: sessionData, signOut } = useAuth()
const loggedIn = computed(() => useAuth().data.value?.accessToken ? true : false)
//***************************************** data
const { isAppDrawerVisible, rightDrawer } = storeToRefs(useUIStore());
const { toggleRightDrawer, toggleDrawer } = useUIStore();

const projectStore = useProjectsStore();
const { currentProject, userProjects, userFormsByProject } =
  storeToRefs(projectStore);
const { fetchUserProjects, fetchUserFormsByProject } = projectStore;

const { leftMenu } = storeToRefs(useUIStore());
const projectBox = ref();

const STORAGE_CURRENT_PROJECT = "fo-current-project";

const project = useLocalStorage<IProject | null>(
  STORAGE_CURRENT_PROJECT,
  null,
  {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v: any) => JSON.stringify(v),
    },
  }
);

// menu 
// const leftMenu = useCookie("fo-left-form-menu", {
//   maxAge: 60 * 60 * 24 * 31,
//   default: () => true,
//   // sameSite: "none",
// });


const toggleLeftFormMenu = () => {
  leftMenu.value = !leftMenu.value
  console.log(`[toggleLeftFormMenu.hideMenu]: ${leftMenu.value}`);
}
currentProject.value = project.value as IProject;

watch(project, async (newVal, oldVal) => {
  // console.log(`[AppHeader] project: ${oldVal?.title}->${newVal?.title}`);
  currentProject.value = newVal as IProject;
});


// left form menu
const isVisibleLeftMenu = computed(() => {
  return sessionData.value?.roles.includes('admin') && !mobile.value
}
)
const leftFormMenuColor = computed(() => leftMenu.value == true ? 'primary' : 'secondary')
const handleSignOut = () => {
  signOut({ callbackUrl: '/auth/login' })
}
onMounted(async () => {
  if (projectBox.value) projectBox.value.focus();
});



watchEffect(async () => {
  if (currentProject.value && userProjects.value.length > 0)
    if (typeof currentProject.value.id === "number") {
      await fetchUserFormsByProject();
    } else {
      cl(
        "AppRightDrawer.watchEffect",
        `NOT CURRENT PROJECT?: ${JSON.stringify(currentProject.value)}`
      );
    }
});

const taskTitle = computed(() => loggedIn.value ? 'Задачи' : 'Задачи (Гость)')


const isProjectBoxVisible = computed(() => {
  if (!loggedIn.value) return false;
  return userProjects.value.length > 0;
})
if (loggedIn.value) {
  await fetchUserProjects();
}

cl(`AppHeader`, `userProjects: ${userProjects.value.length}`);

// cs(`AppHeader`, `loggedIn: ${loggedIn.value}`);

</script>

<template>
  <div>
    <v-app-bar app>
      <template v-slot:prepend>
        <v-app-bar-nav-icon v-if="isAppDrawerVisible"
                            @click="toggleDrawer"></v-app-bar-nav-icon>
        <NuxtLink class="ml-4 mr-4"
                  to="/">
          <NuxtImg src="/img/logo_dark.svg"
                   width="160" />
        </NuxtLink>

      </template>
      
      

      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-btn variant="text"
               v-if="isVisibleLeftMenu"
               @click="toggleLeftFormMenu()"
               :color="leftFormMenuColor"
               icon="mdi-dock-left" />

        <v-btn v-if="!loggedIn"
               to="/auth/login">Войти</v-btn>

        <v-menu v-else>

          <template v-slot:activator="{ props }">
            <v-btn color="primary"
                   v-bind="props">
              {{ sessionData?.username }}
            </v-btn>
          </template>
          <v-list density="compact">
            <v-list-item prepend-icon="mdi-exit-to-app"
                         @click="handleSignOut">
              Выйти
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-if="isProjectBoxVisible"
             class="mt-6 me-2 ms-2"
             style="width: 200px">
          <v-autocomplete ref="projectBox"
                          variant="outlined"
                          label="Проект"
                          density="compact"
                          v-model="project"
                          :items="userProjects"
                          item-title="title"
                          item-value="id"
                          auto-select-first
                          return-object>
          </v-autocomplete>
        </div>
        <v-app-bar-nav-icon @click="toggleRightDrawer"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
  </div>
</template>
